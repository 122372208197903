export const personal_info={
    fullName:'Miniyahil Kebede',
    subtitle:' Experienced Software Engineer from Ethiopia with 5+ years of experience',
    email:'miniye6453@gmail.com',
    phone:'+251946553371',
    resume_link:'https://drive.google.com/file/d/1pVZEj9pectKGJ3e5jcK2TBu1guLG3ASG/view?usp=sharing'
}

export const links={
    github:'https://github.com/hmhard',
}