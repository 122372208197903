import { ProjectProps } from "../shared/types";

export const project_data: Array<ProjectProps> = [
  {
    name: "Student Admission and Placement System",
    description:
      "I worked on the Student Admission and Placement System for the Ethiopian Ministry of Education. This system manages student information, determines cut-off points based on given parameters, and processes university placements for eligible students according to a specified algorithm. Additionally, the system provides student alumni services and includes a result portal.",
    link: "https://saps.ethernet.edu.et/",
    company: "Ministry of education",
    technologies: ["React", "NextJs", "Spring Boot", "Python"],
    image_url: "/images/saps.jpg",
  },
  {
    name: "One card system",
    description:
      "I collaborated with a development team to create a unified card system for managing all university services. This system integrates over 12 services within the university, providing students with seamless access to multiple services through a single card.",
    link: "https://www.sis.ju.edu.et",
    company: "Jimma University",
    technologies: ["React", "Laravel", "Mysql"],
    image_url: "/images/sis.jpg",
  },
 
  {
    name: "National Agridata hub",
    description:
      "worked on datahub software which Gather data from disparate sources, Integrate data into a unified view for data consumers, Prepare data for analytics and reporting, Manage data pipelines for a continuous flow of data from sources to the Data HUB, Manage the complete infrastructure for the collection, processing, and storage of data.",
    link: "https://datahub.moa.gov.et/",
    company: "Ministry of Agriculture",
    technologies: ["NestJs", "NextJs", "ArrangoDB", "Jenkins"],
    image_url: "/images/ag-datahub-home.jpg",
  },
  {
    name: "Budget Tool System",
    description:
      "I contributed to the development of a Project Budget Tool utilized by JSI to manage budgets across more than 200 global projects. This tool facilitates comprehensive project management, encompassing planning, approval processes, budgeting, and the generation of final reports.",
    link: "https://pbt.jsi.com/",
    company: "John Snow Inc.",
    technologies: ["React", "Spring Boot", "Postgres"],
    image_url:
      "https://cdn-icons-png.flaticon.com/512/5433/5433756.png?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
  },
  {
    name: "Research Portal",
    description:
      "I have worked on a Research Portal, which serves as a comprehensive platform for accessing and managing academic research resources. This portal facilitates easy navigation and discovery of scholarly articles, research data, and publications across various disciplines. It includes tools for collaboration among researchers, such as discussion forums and project sharing capabilities.",
    link: "https://research.ju.edu.et/",
    company: "Jimma University",
    technologies: ["React", "Laravel", "Mysql"],
    image_url: "/images/research.jpg",
  },
  {
    name: "Gate Control System",
    description:
      "I have worked Gate Control System designed to regulate entry and exit within a company's premises. This system effectively manages community access by verifying personnel credentials upon scanning RFID cards. Once scanned, pertinent user information is promptly displayed on screens positioned around the gate, ensuring only authorized personnel can enter",
    link: "https://gcs.ju.edu.et/",
    technologies: ["React", "Django", "Mysql"],
    company: "Jimma University",
    image_url: "/images/gate.jpg",
  },
  {
    name: "Student Portal",
    description:
      "I have developed a Student Portal that serves as a centralized gateway providing seamless access to all university services from a single platform. This portal enhances the student experience by consolidating functionalities such as course registration, grades, academic resources, and administrative services into one cohesive interface. Students can conveniently access and manage their schedules, financial information, campus events, and communication tools through this integrated system.",
    link: "https://portal.ethernet.edu.et/",
    technologies: ["React", "Spring Boot", "Mysql"],
    company: "Ministry of Education",
    image_url: "/images/student-portal.jpg",
  },
  {
    name: "Telemedicine",
    description:
      "I have contributed to Telemedicine initiatives, focusing on leveraging technology to provide remote medical services and consultations. This involved developing and enhancing mobile applications and platforms that facilitate virtual interactions between healthcare providers and patients, particularly focusing on individuals affected by HIV.",
    link: "https://telement.ju.edu.et/",
    technologies: ["Android", "SMS", "Java", "Firebase"],
    company: "Oromia region Health Beurue",
    image_url: "/images/telemedicine.jpg",
  },
  {
    name: "Ethiopian Universities App",
    description:
      "I have worked on an application designed to provide comprehensive information about universities across Ethiopia while fostering connectivity among students within different campuses. This app serves as a centralized platform where students can access detailed profiles of universities, including academic programs, campus facilities, and extracurricular activities",
    link: "https://github.com/hmhard/ethiopian-universities-info",
    technologies: ["Flutter", "Dart", "Google Map", "Appwrite"],
    company: "Open source",
    image_url: "/images/ethio.jpg",
  },
];
