export const experience_data = {
    title: "Experience",
    subtitle: "Work, Internship and Volunteership",
    description:
    "I have worked in diverse development areas such as health, education, fintech, and agriculture to enhance daily activities and solve complex algorithms. I developed systems capable of handling over a million requests per day and addressed challenges in both national and international systems.",    sections: [
      {
        title: "Work",
        work: true,
        experiences: [
            {
              title: "Software Engineer",
              company: "John Snow Inc.",
              company_url: "https://www.jsi.com/",
              logo_path: "jsi.png",
              duration: "Sep 2023 - Current",
              location: "Addis Ababa, Ethiopia",
              description:
              "I worked on a financial system called the Project Budget Tool, which manages the budgets for over 200 projects worldwide. The system handles everything from initial budget planning and approval to final reporting. Additionally, I customized OpenLMIS for the Madagascar Ministry of Health to manage health logistics from the national level down to individual health centers.",
              color: "#fc1f20",
            },
           
            {
              title: "Full-stach Dev",
              company: "Acatch",
              company_url: "https://acatechte.com/",
              logo_path: "acateche.png",
              duration: "Oct 2022 - Present",
              location: "Addis ababa Ethiopia",
              description:
              "I have worked on a system called DataHub, designed to handle a national-level data warehouse for storing and processing agricultural data for research and decision-making purposes. This centralized data repository serves national and international clients and researchers. Additionally, I developed a farm management system to manage various farm-related data such as soil, rainfall, weather, humidity, and other relevant information across the country.",
                            color: "#9b1578",
            },
            {
              title: "Software Engineer",
              company: "Jimma University",
              company_url: "https://ju.edu.et/",
              logo_path: "ju.png",
              duration: "July 2019 - Aug 2022",
              location: "Jimma, Ethiopia",
              description:
              "I have worked on educational software to address issues and enhance the experience for both universities and the broader community.",
              color: "#0879bf",
            },
            
          ],
      },
      {
        title: "Internships",
        experiences: [
          {
            title: "Software Programmer Intern",
            company: "Jimma University",
            company_url: "https://www.ju.edu.et/",
            logo_path: "tiktok_logo.png",
            duration: "Feb 2018 - Aug 2018",
            location: "Wolaita, Ethiopia",
            description:
            "I have developed a telemedicine app to reach out to HIV patients, provide consultations, and raise awareness through mobile applications.",
            color: "#000000",
          },
        ],
      },
      {
        title: "Volunteerships",
        experiences: [
        ],
      },
    ],
  };